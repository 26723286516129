.mainWrapperBlock:deep() {
    width: 80vw;
    margin-top: 1rem;

    .mainWrapper {
        width: 100%;
        background-color: #131313;
        border-radius: 0.8rem;
        min-height: 200px;
        overflow: hidden;

        .wrapper-header {
            width: 100%;
            min-height: 40px;
            background-color: #161616;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .innerBlock {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}