
@import 'src/styles/scss/header.scss';

.flyFromRight-enter-from,
.flyFromRight-leave-to {
    transform: translateX(100%);
}

.flyFromRight-enter-active,
.flyFromRight-leave-active {
    transition: transform 0.3s;
}

