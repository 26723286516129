:root {
    --bg-color: #1E1E1E;
    --primary-color: #111111;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;

    &:hover {
        text-decoration: none;
        color: inherit;
    }
}

body {
    background-color: var(--bg-color);
    color: aliceblue;

    position: relative;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.yesselect {
    -webkit-touch-callout: auto;
    -webkit-user-select: auto;
    -khtml-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
}

.uim-svg svg {
    vertical-align: top;
}

#app {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}