.panel-header-block {
    z-index: 21;
    width: 80vw;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .header-logo-block {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        width: 4.5rem;
        height: 4rem;
        background-color: var(--primary-color);
        border-radius: 0.8rem;
        overflow: hidden;

        > img {
            height: 130%;
        }
    }

    .header-menu-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 50%;
        height: 4rem;
        background-color: var(--primary-color);
        border-radius: 0.8rem;
        padding-left: 0.5rem;
        color: #4C4C4C;

        > .item {
            cursor: pointer;
            margin-left: 1.5rem;

            &.active,
            &.router-link-exact-active {
                color: white;
            }
        }

        .selector_wrapper {
            margin-left: auto;
            margin-right: 1rem;
        }

        .menuOpenBtn {
            cursor: pointer;
            background-color: #363636;
            font-size: 1.2rem;
            color: white;
            margin-right: 1rem;
            display: none;
        }

        @media screen and (max-width: 930px) {
            justify-content: flex-end;

            > .item,
            > .selector_wrapper:not(.mobile-menu) {
                display: none;
            }

            .menuOpenBtn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2rem;
                height: 2rem;
                background-color: #4C4C4C;
                border-radius: 0.4rem;
                margin-left: 1rem;
            }
        }

        @media screen and (max-width: 1640px) {
            width: calc(100% - 4.5rem - 1rem);
        }
    }
}

.panel-header-sub-block {
    margin-top: 1rem;
    width: 80vw;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @media screen and (max-width: 860px) {
        display: none;
    }

    .actionBlock {
        text-decoration: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 1rem;
        background-color: var(--primary-color);
        width: calc(80vw / 3 - 0.5rem);
        height: 4.5rem;
        border-radius: 0.8rem;

        &:hover {
            background-color: #151515;
        }

        .logoBlock {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 2rem;
            width: 2rem;
            border-radius: 0.4rem;

            &.discord {
                background-color: #443FCF;
            }
            &.telegram {
                background-color: #01A0F3;
            }
            &.vk {
                background-color: #0077FF;

                > img {
                    height: 60%;
                    width: 60%;
                }
            }

            > img {
                height: 80%;
                width: 80%;
            }
        }

        .text {
            margin-left: 1rem;
            font-size: 0.9rem;
            font-weight: 500;
            color: white;
        }
    }
}

.mobileMenu {
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--primary-color);

    padding-top: calc(2rem + 4rem + 1rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.3rem;

    .menuItem {
        padding: 1rem;
        border-top: 1px solid #1B1B1B;
        width: 80%;
        text-align: center;

        &:last-child {
            border-bottom: 1px solid #1B1B1B;
        }
    }
}