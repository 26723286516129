.selector_wrapper {
    border-radius: 0.5rem;
    color: #fff;
    border: 1px solid #e6e6e633;
    position: relative;
    padding: 0.2rem;
    padding-left: 0.5rem;

    .true_language {
        display: flex;
        align-items: center;
        cursor: pointer;

        > span {
            display: flex;
            justify-content: center;

            > img {
                margin-right: 0.3rem;
            }
        }

        > i {
            color: #e6e6e680;
        }
    }

    select {
        position: absolute;
        top: 0;
        opacity: 0;
        height: 100%;
        appearance: none;
        background: transparent;
        border: none;
        outline: none;
        color: #8e8e8e;
        font-weight: 400;
        font-size: 0.85rem;
        letter-spacing: 0.4px;
        width: 100%;
        cursor: pointer;
    }
}